import revive_payload_client_7GdcXCoHN3 from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.3.0_eslint@8.55.0_ioredis@5.4.1_magicast@0.3_jmh7qswdzf2gbvfus4czxuav6e/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Oq44qWIik5 from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.3.0_eslint@8.55.0_ioredis@5.4.1_magicast@0.3_jmh7qswdzf2gbvfus4czxuav6e/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_AMG1l0ma7O from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.3.0_eslint@8.55.0_ioredis@5.4.1_magicast@0.3_jmh7qswdzf2gbvfus4czxuav6e/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_tq6Sf4jnPs from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.3.0_eslint@8.55.0_ioredis@5.4.1_magicast@0.3_jmh7qswdzf2gbvfus4czxuav6e/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_mDzedWZ0m5 from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.3.0_eslint@8.55.0_ioredis@5.4.1_magicast@0.3_jmh7qswdzf2gbvfus4czxuav6e/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_lybmGC5zns from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.3.0_eslint@8.55.0_ioredis@5.4.1_magicast@0.3_jmh7qswdzf2gbvfus4czxuav6e/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_N5zt9WQHbc from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.3.0_eslint@8.55.0_ioredis@5.4.1_magicast@0.3_jmh7qswdzf2gbvfus4czxuav6e/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_et1e97p5jW from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_magicast@0.3.4_rollup@4.20.0_vue@3.4.37_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import amplitude_client_a5NDjvTWdZ from "/app/plugins/amplitude.client.ts";
import api_GFfDXud5Cr from "/app/plugins/api.ts";
import append_parameters_directive_KEd3bskr0F from "/app/plugins/append-parameters-directive.ts";
import ccm19_client_MmBPIpSdFj from "/app/plugins/ccm19.client.ts";
import gtm_client_OzmBuHLRIb from "/app/plugins/gtm.client.ts";
import matomo_client_Ed2EqINkhd from "/app/plugins/matomo.client.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
import third_party_integrations_client_tQxxkJnfI1 from "/app/plugins/third-party-integrations.client.ts";
export default [
  revive_payload_client_7GdcXCoHN3,
  unhead_Oq44qWIik5,
  router_AMG1l0ma7O,
  payload_client_tq6Sf4jnPs,
  navigation_repaint_client_mDzedWZ0m5,
  chunk_reload_client_lybmGC5zns,
  components_plugin_KR1HBZs4kY,
  prefetch_client_N5zt9WQHbc,
  i18n_et1e97p5jW,
  amplitude_client_a5NDjvTWdZ,
  api_GFfDXud5Cr,
  append_parameters_directive_KEd3bskr0F,
  ccm19_client_MmBPIpSdFj,
  gtm_client_OzmBuHLRIb,
  matomo_client_Ed2EqINkhd,
  sentry_client_shVUlIjFLk,
  third_party_integrations_client_tQxxkJnfI1
]