import { createGtm } from "@gtm-support/vue-gtm"

export default defineNuxtPlugin({
  name: "GTM",
  dependsOn: ["CCM19"],
  async setup(nuxtApp) {
    /**
     * This only enables the GTM Nuxt plugin.
     *
     * GTM itself might not be enabled really,
     * because CCM19 might have blocked it still
     */
    const { public: { gtm: { id } } } = useRuntimeConfig()
    const { data: jobShop } = useNuxtData("jobShopData")

    const useMatomo = jobShop.value?.externalScriptSettings.isCookielessTrackingEnabled

    if (!useMatomo) {
      nuxtApp.vueApp.use(createGtm({
        id,
        enabled: false,
        debug: process.dev,
        trackViewEventProperty: "jobfinderView",
      }))

      function activatePlugin() {
        useGtm()?.enable(true)
      }

      // initalize after cookie consent closed
      window.addEventListener("ccm19WidgetClosed", () => {
        activatePlugin()
      })

      // or if there still is consent from before
      window.addEventListener("ccm19WidgetLoaded", () => {
        activatePlugin()
      })
    }
  },
})
