import { default as _91slug_93msfiAekumQMeta } from "/app/pages/[slug].vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug()",
    component: () => import("/app/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  }
]