import routerOptions0 from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.3.0_eslint@8.55.0_ioredis@5.4.1_magicast@0.3_jmh7qswdzf2gbvfus4czxuav6e/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/app/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}